import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyCV24koC5FIunTMZxHte_SHJpLBsChkDu8',
  authDomain: 'go-hang.web.app',
  databaseURL: 'https://go-hang.firebaseio.com',
  projectId: 'go-hang',
  storageBucket: 'go-hang.appspot.com',
  messagingSenderId: '',
  appId: '',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection('users');
const postsCollection = db.collection('posts');
const commentsCollection = db.collection('comments');
const likesCollection = db.collection('likes');

// export utils/refs
export {
  db, auth, usersCollection, postsCollection, commentsCollection, likesCollection,
};
