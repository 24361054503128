import { Post } from '@/models/Post';
import { UserProfile } from '@/models/UserProfile';
import Vue from 'vue';
import Vuex from 'vuex';
import * as fb from '../firebase';
import router from '../router/index';

Vue.use(Vuex);

export interface AppState {
  $store?: any;
  posts?: Post[];
  userProfile?: UserProfile | null;
  error?: Error | null;
}

const store = new Vuex.Store({
  state: {
    posts: [],
    userProfile: {},
    error: null,
  } as AppState,
  mutations: {
    clearError(state) {
      state.error = null;
    },
    setError(state, val) {
      state.error = val;
    },
    setPosts(state, val) {
      state.posts = val;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
  },
  actions: {
    async createPost({ state, commit }, postInput) {
      const post = {
        createdOn: new Date(),
        content: postInput.content,
        userId: fb.auth.currentUser?.uid,
        userName: state.userProfile?.userName,
        comments: 0,
        likes: 0,
      };
      await fb.postsCollection.add(post);
    },
    async likePost({ commit }, post) {
      const userId = fb.auth.currentUser?.uid;
      const docId = `${userId}_${post.id}`;
      // check if user has liked post
      const doc = await fb.likesCollection.doc(docId).get();
      if (doc.exists) {
        return;
      }
      // create post
      await fb.likesCollection.doc(docId).set({
        postId: post.id,
        userId,
      });
      // update post likes count
      fb.postsCollection.doc(post.id).update({
        likes: post.likesCount + 1,
      });
    },
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password);

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user);
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get();

      // set user profile in state
      commit('setUserProfile', userProfile.data());

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/');
      }
    },

    async signup({ dispatch }, form) {
      store.commit('clearError');
      // sign user up
      try {
        const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password);
        // create user profile object in userCollections
        if (user) {
          await fb.usersCollection.doc(user.uid).set({
            userName: form.userName,
            firstName: form.firstName,
            email: form.email,
            phone: form.phone,
          });
          // fetch user profile and set in state
          dispatch('fetchUserProfile', user);
        }
      } catch (error) {
        store.commit('setError', error);
      }
    },
    async logout({ commit }) {
      await fb.auth.signOut();
      // clear userProfile and redirect to /login
      commit('setUserProfile', {});
      router.push('/login');
    },
    async updateProfile({ dispatch }, user: UserProfile) {
      const userId = fb.auth.currentUser?.uid;
      // update user object
      const userRef = await fb.usersCollection.doc(userId).update({
        userName: user.userName,
        firstName: user.firstName,
      });
      dispatch('fetchUserProfile', { uid: userId });
      // update all posts by user
      const postDocs = await fb.postsCollection.where('userId', '==', userId).get();
      postDocs.forEach((doc) => {
        fb.postsCollection.doc(doc.id).update({
          userName: user.userName,
        });
      });
      // update all comments by user
      const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get();
      commentDocs.forEach((doc) => {
        fb.commentsCollection.doc(doc.id).update({
          userName: user.userName,
        });
      });
    },
  },
  modules: {},
});

fb.postsCollection.orderBy('createdOn', 'desc').onSnapshot((snapshot) => {
  const postsArray: any[] = [];
  snapshot.forEach((doc) => {
    const post = doc.data();
    post.id = doc.id;
    postsArray.push(post);
  });

  store.commit('setPosts', postsArray);
});

export default store;
