import Vue from 'vue';
import { auth } from 'firebase';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/app.scss';

Vue.config.productionTip = false;

let app: Vue;
auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');

    if (user) {
      store.dispatch('fetchUserProfile', user);
    }
  }
});
